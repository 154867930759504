const basePath = '/wt/api/v1';
const endpoints = {
    getPageDataByIds: (ids) => `${basePath}/bookmarks/?ids=${ids.join(',')}`,
    getSearchResults: (searchString) =>
        `${basePath}/search-filter/?${searchString}`,
    getSearchSuggestions: (searchString, parent) =>
        `${basePath}/search-filter-suggestions/?query=${searchString}&parent=${parent}`,
    sendArticleFeedback: `${basePath}/articlefeedbackentry/`,
    sendArticleFeedbackComment: (feedbackId) =>
        `${basePath}/articlefeedbackentry/${feedbackId}/`,
};

const getPageDataByIds = async (ids) => {
    const response = await fetch(endpoints.getPageDataByIds(ids));
    return await response.json();
};

const getSearchResults = async (
    page,
    searchInput,
    activeChips,
    activeRegions,
    parent,
    pinnedCategory,
    sorting,
    abortController
) => {
    let signal;

    if (abortController) {
        signal = abortController.signal;
    }

    let regions = '';
    if (activeRegions && activeRegions.length > 0) {
        regions = activeRegions.join(',');
    }

    let categories = '';
    if (activeChips && activeChips.length > 0) {
        categories = activeChips.join(',');
    }

    const searchString = `query=${searchInput}&categories=${categories}&parent=${parent}&page=${page}&regions=${regions}&pinned_category=${
        pinnedCategory || ''
    }&sorting=${sorting}`;
    return fetch(endpoints.getSearchResults(searchString), { signal });
};

const getSearchSuggestions = async (searchString, parent) => {
    const response = await fetch(
        endpoints.getSearchSuggestions(searchString, parent)
    );
    return await response.json();
};

const sendArticleFeedback = async (articleId, rating) => {
    const response = await fetch(endpoints.sendArticleFeedback, {
        method: 'POST',
        body: JSON.stringify({ article: articleId, rating }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return await response.json();
};

const sendArticleFeedbackComment = async (feedbackId, comment) => {
    const response = await fetch(
        endpoints.sendArticleFeedbackComment(feedbackId),
        {
            method: 'PATCH',
            body: JSON.stringify({ comment }),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return await response.json();
};

export {
    getPageDataByIds,
    getSearchResults,
    getSearchSuggestions,
    sendArticleFeedback,
    sendArticleFeedbackComment,
};
