import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import * as bookmarks from '../../utils/bookmarks';

import styles from './NavigationFavorite.module.scss'

const NavigationFavorite = ({
    favoritesPageUrl,
    onHover,
    onHoverLeave,
    hasMouseOverThisItem,
    mouseOverItem,
}) => {
    const { t } = useTranslation("common")
    const [isActive, setIsActive] = useState(false);
    const [favoritesCount, setFavoritesCount] = useState(0);

    const handleBookmarksUpdated = (updatedBookmarks) =>
        setFavoritesCount(updatedBookmarks.length);

    useEffect(() => {
        setFavoritesCount(bookmarks.getBookmarks().length);

        setIsActive(
            typeof window !== 'undefined' &&
                window.location.href === favoritesPageUrl
        );

        bookmarks.onBookmarksUpdated(handleBookmarksUpdated);
        return () => {
            bookmarks.removeOnBookmarksUpdated(handleBookmarksUpdated);
        };
    }, [favoritesPageUrl]);

    const favoriteClasses = classNames(styles["NavigationFavorite"], {
        [styles["NavigationFavorite--Filled"]]: favoritesCount > 0,
        [styles["NavigationFavorite--Active"]]: isActive,
        [styles["NavigationFavorite--Fade"]]:
            !hasMouseOverThisItem && mouseOverItem !== undefined,
    });

    return (
        <a
            className={favoriteClasses}
            onMouseOver={onHover}
            onFocus={onHover}
            onMouseLeave={onHoverLeave}
            href={favoritesPageUrl}
            data-number={favoritesCount < 100 ? favoritesCount : 99}>
            <span className="sr-only">{t('navigationfavorite.link')}</span>
            {favoritesCount > 0 && (
                <span className={styles["NavigationFavorite__NumberContainer"]}>
                    <span className="NavigaationFavorite__Number">
                        {favoritesCount < 100 ? favoritesCount : 99}
                    </span>
                </span>
            )}
        </a>
    );
};

NavigationFavorite.propTypes = {
    favoritesPageUrl: PropTypes.string,
    onHover: PropTypes.func,
    onHoverLeave: PropTypes.func,
    hasMouseOverThisItem: PropTypes.bool,
    mouseOverItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

NavigationFavorite.defaultProps = {
    favoritesPageUrl: '',
};

export default NavigationFavorite;
