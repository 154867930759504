import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './GlobalSiteSwitcher.module.scss'

const GlobalSiteSwitcher = ({ label, links }) => {
    const [open, setOpen] = useState(false);

    if (!links.length) {
        return null;
    }

    const handleKeyDown = (e) => {
        const tabKey = 9;
        if (e.keyCode == tabKey) {
            return;
        }
        setOpen(!open);
    };

    return (
        <div className={styles["GlobalSiteSwitcher"]}>
            <div className={styles["GlobalSiteSwitcher__Content"]}>
                <button
                    className={styles["GlobalSiteSwitcher__Button"]}
                    onClick={() => setOpen(!open)}>
                    <span className={styles["GlobalSiteSwitcher__Label"]}>{label}</span>
                    <div
                        className={classNames(styles["GlobalSiteSwitcher__Arrow"], {
                            [styles["GlobalSiteSwitcher__Arrow--Open"]]: open,
                        })}
                    />
                </button>
                <ul
                    className={classNames(styles["GlobalSiteSwitcher__Items"], {
                        [styles["GlobalSiteSwitcher__Items--Open"]]: open,
                    })}>
                    {links.map(({ value }, i) => (
                        <li key={i}>
                            <a
                                className={styles["GlobalSiteSwitcher__Link"]}
                                href={value.href}>
                                {value.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

GlobalSiteSwitcher.propTypes = {
    label: PropTypes.string,
    links: PropTypes.array,
};

GlobalSiteSwitcher.defaultProps = {
    label: '',
    links: [],
};

export default GlobalSiteSwitcher;
