import React from 'react';
import { useTranslation } from 'next-i18next';

import styles from './BasicLogo.module.scss'

const BasicLogo = () => {
    const { t } = useTranslation("common")
    return (
        <div className={styles["BasicLogo"]}>
            <div className={styles["BasicLogo__Wrapper"]}>
                <div className={styles["BasicLogo__Logotype"]}>
                    <a href="/">
                        <span className="sr-only">
                            {t('navigation.logo')}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default BasicLogo;
