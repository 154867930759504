import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import styles from './NewsletterSignup.module.scss'

const NewsletterSignup = ({ newsletterUrl, hiddenInputName }) => {
    const { t } = useTranslation("common")
    const text = t('newsletterSignup.text');
    const placeholder = t('newsletterSignup.placeholder');
    const submitText = t('newsletterSignup.submitText');
    const label = t('newsletterSignup.label');

    return (
        <div className={styles["NewsletterSignup"]}>
            <div className={styles["NewsletterSignup__Wrapper"]}>
                <div className={styles["NewsletterSignup__Text"]}>{text}</div>
                <div className={styles["NewsletterSignup__Input"]}>
                    <form
                        action={newsletterUrl}
                        method="post"
                        target="_blank"
                        noValidate={true}>
                        <label className="sr-only" htmlFor="signupEmail">
                            {label}
                        </label>
                        <input
                            id="signupEmail"
                            type="email"
                            defaultValue=""
                            name="EMAIL"
                            placeholder={placeholder}
                        />
                        <div
                            style={{ position: 'absolute', left: '-5000px' }}
                            aria-hidden="true">
                            <input
                                type="text"
                                name={hiddenInputName}
                                tabIndex="-1"
                                value=""
                                readOnly={true}
                            />
                        </div>
                        <input
                            type="submit"
                            value={submitText}
                            name="subscribe"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

NewsletterSignup.propTypes = {
    newsletterUrl: PropTypes.string,
    hiddenInputName: PropTypes.string,
};

NewsletterSignup.defaultProps = {
    text: '',
    newsletterUrl: '',
    placeholder: '',
    hiddenInputName: '',
    submitText: '',
};

export default NewsletterSignup;
